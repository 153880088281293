<template>
  <div v-show="saveEFormsPayload" id="app" :class="{'scroll-me': showForm}">
    <div class="formio-header">
      <div class="md-layout-item vasion-page-title left-padding margin-bottom">
        <VasionInput
          id="new-formio-title"
          v-model="saveEFormsPayload.Name"
          name="new-formio-title"
          class="input-style"
          inputType="title-input"
          :isTitleInput="true"
          :placeholder="`Untitled ${$layoutsLabel}`"
        />
      </div>
      <div v-if="showForm && selectedTabName === 'build'" class="with-fix col-xs-4 col-sm-3 col-md-2">
        <div class="formio-input-extension">
          <VasionButton
            id="formio-input-extension-add"
            :isDisabled="isLoading"
            :isFullWidth="true"
            :classProp="'secondary'"
            @vasionButtonClicked="$refs.newFieldDialog.toggleAddNewField()"
          >
            Add New Field
          </VasionButton>
          <VasionButton
            v-if="showFormioInputs"
            id="formio-input-extension-view"
            :isDisabled="isLoading"
            :isFullWidth="true"
            :classProp="'secondary'"
            @vasionButtonClicked="toggleComponentView(false)"
          >
            View {{ $formsLabel }} Fields
          </VasionButton>
          <VasionButton
            v-else
            id="formio-input-extension-view"
            :isDisabled="isLoading"
            :isFullWidth="true"
            :classProp="'secondary'"
            @vasionButtonClicked="toggleComponentView(true)"
          >
            View {{ $layoutsLabel }} Fields
          </VasionButton>
          <VasionInput
            v-if="!showFormioInputs"
            id="quick-find-input-extension"
            v-model="fieldSearchInput"
            v-debounce:300ms="runComponentFilter"
            autocomplete="off"
            class="quick-find-input-extension"
            inputType="search-gray"
            name="quick-find-input-extension"
            placeholder="Quick Find..."
          />
        </div>
      </div>
      <div class="col-xs-8 col-sm-9 col-md-10">
        <div v-if="showForm && selectedTabName === 'build'" class="formio-extension">
          <input
            id="form-name"
            v-model="saveEFormsPayload.Name"
            autocomplete="off"
            class="form-name"
            inputType="top-white"
            name="form-name"
            :placeholder="`Untitled ${$layoutsLabel}`"
          >
          <input
            id="form-description"
            v-model="saveEFormsPayload.Description"
            autocomplete="off"
            class="form-description"
            inputType="top-white"
            name="form-description"
            :placeholder="`${$layoutsLabel} Description`"
          >
        </div>
        <div v-show="selectedTabName === 'build'" class="custom-logo-extension">
          <div class="header-container">
            <VasionButton
              v-if="!isDocumentLoaded"
              id="eform-save"
              :isDisabled="isLoading"
              :classProp="'secondary'"
              @vasionButtonClicked="showUploadDialog = !showUploadDialog"
            >
              Upload Header Image
            </VasionButton>
            <img v-if="isDocumentLoaded" class="header-image" :src="localHeaderImage">
          </div>
          <div v-if="isDocumentLoaded" class="header-icon-buttons">
            <md-button
              id="editIconBtn"
              class="md-dense action-button highlightBackground"
              title="Edit Image"
              @click="editHeaderImage"
            >
              <VasionEditIcon />
            </md-button>
            <md-button
              id="deleteIconBtn"
              class="md-dense action-button highlightBackground"
              title="Remove Image"
              @click="deleteHeaderImage"
            >
              <VasionExIcon />
            </md-button>
          </div>
        </div>
        <div class="md-layout-item vasion-flex-end">
          <VasionButton
            id="eform-back-to-form"
            :isDisabled="isLoading"
            :class="{'eform-buttons' : selectedTabName === 'build'}"
            :classProp="'text'"
            @vasionButtonClicked="backToLayouts()"
          >
            Back to {{ $layoutsLabelPlural }}
          </VasionButton>
          <VasionButton
            id="eform-save"
            :isDisabled="isLoading"
            :class="{'eform-buttons' : selectedTabName === 'build'}"
            :classProp="'primary'"
            @vasionButtonClicked="save()"
          >
            Save
          </VasionButton>
        </div>
      </div>
      <div class="tabs">
        <VasionButton id="tab-settings" :classProp="calculatedClass('build')" @vasionButtonClicked="updateSelectedTab('build')">
          Build
        </VasionButton>
        <VasionButton id="tab-logic" :classProp="calculatedClass('settings')" @vasionButtonClicked="updateSelectedTab('settings')">
          Settings
        </VasionButton>
      </div>
    </div>
    <div v-show="selectedTabName === 'build'">
      <Loading
        class="vasion-loading-indicator"
        :active.sync="isLoading"
        :is-full-page="false"
        :color="loaderColor"
        loader="dots"
        :background-color="loaderBackgroundColor"
      />
      <div id="">
        <div v-if="showForm && shouldBuilderLoad" id="vasion-form-builder">
          <formbuilder
            :key="builderKey"
            ref="formBuilder"
            :form="activeFormIoJSON"
            :options="config"
            @change="setEFormBuilderIsDirty"
            @removeComponent="removeHandler"
            @addComponent="componentAdded"
            @saveComponent="componentSaved"
          />
        </div>
      </div>
    </div>
    <div v-show="selectedTabName === 'settings'" class="map-forms">
      <div class="vasion-settings-card">
        <div class="form-builder-search-group">
          <div class="vasion-flex">
            <VasionInput
              id="rejected-destination"
              v-model="saveEFormsPayload.FolderName"
              class="row-field browse-input"
              title="DESTINATION FOLDER"
              inputType="top-white"
              name="rejected-destination"
              placeholder="Select Folder..."
              :width="'306'"
              :isDisabled="true"
            />
            <VasionButton
              id="browse-folders-for-eform"
              :classProp="'primary'"
              @vasionButtonClicked="() => showBrowseFoldersDialog = !showBrowseFoldersDialog"
            >
              Browse
            </VasionButton>
          </div>
        </div>
        <div class="vasion-flex">
          <VasionDropList
            id="database-lookup-dropdown"
            v-slot="slotItem"
            v-model="databaseLookup"
            :dataArray="lookupList"
            title="Database Lookup"
            name="database-lookup-dropdown"
            width="306"
            valueName="id"
            displayName="name"
            placeholder="Select a Database Lookup..."
          >
            {{ slotItem.item.name }}
          </VasionDropList>
          <VasionCheckbox
            id="show-lookup-button-checkbox"
            class="row-checkbox"
            name="show-lookup-button-checkbox"
            :isDisabled="databaseLookup.id === null"
            :checked="saveEFormsPayload.ShowLookupButton"
            @disabled="saveEFormsPayload.ShowLookupButton = false"
            @change="saveEFormsPayload.ShowLookupButton = !saveEFormsPayload.ShowLookupButton"
          >
            Show Lookup Button
          </VasionCheckbox>
        </div>
        <VasionCheckbox
          id="allow-file-upload"
          class="form-builder-margin-10 fixedWidth"
          name="allow-file-upload"
          :checked="saveEFormsPayload.AllowUploadFiles"
          @change="saveEFormsPayload.AllowUploadFiles = !saveEFormsPayload.AllowUploadFiles"
        >
          Allow File Upload
        </VasionCheckbox>
        <VasionCheckbox
          id="require-user-signature"
          class="form-builder-margin-10 fixedWidth"
          name="require-user-signature"
          :checked="saveEFormsPayload.RequiresSignature"
          @change="saveEFormsPayload.RequiresSignature = !saveEFormsPayload.RequiresSignature"
        >
          Requires User Signature
        </VasionCheckbox>
        <VasionDropList
          v-slot="slotItem"
          v-model="selectedWorkflow"
          class="droplist"
          :dataArray="workflowList"
          width="396"
          title="ASSIGN TO WORKFLOW"
          placeholder="Select Workflow..."
          type="plain-list"
          displayName="name"
          valueName="value"
        >
          {{ slotItem.item.name }}
        </VasionDropList>

        <div class="collapsible-section">
          <VasionButton
            name="collapse-button"
            class="collapse-button"
            :classProp="'secondary-light'"
            :icon="showFormLayoutAccess ? 'VasionArrowDropDown16Icon' : 'VasionArrowDropDownRight16Icon'"
            title="Collapse Button"
            @vasionButtonClicked="showFormLayoutAccess = !showFormLayoutAccess"
          />
          <h2 class="subheader">
            {{ $layoutsLabel }} Access
          </h2>
        </div>
        <div v-show="showFormLayoutAccess">
          <VasionCheckbox
            id="allow-for-public-access"
            class="form-builder-margin-10 fixedWidth"
            name="allow-for-public-access"
            :checked="saveEFormsPayload.PublicAccessible"
            @change="saveEFormsPayload.PublicAccessible = !saveEFormsPayload.PublicAccessible"
          >
            Allow For Public Access
          </VasionCheckbox>
          <p class="description-text">
            Public access will allow for anyone with the link to view the {{ $formsLabel.toLowerCase() }}'s {{ $layoutsLabel.toLowerCase() }}.
          </p>
          <div v-if="!saveEFormsPayload.PublicAccessible">
            <div>
              <VasionListSelection
                :available-fields.sync="availableUsers"
                :selected-fields.sync="assignedUsers"
                :allow-field-ordering="false"
                :available-label-text="'All Users'"
                :selected-label-text="'Permitted Users'"
                :unique-id="'permissions-users'"
                @update:availableFields="setEFormBuilderIsDirty"
                @update:selectedFields="setEFormBuilderIsDirty"
                @update:addAll="setEFormBuilderIsDirty"
                @update:removeAll="setEFormBuilderIsDirty"
              />
            </div>
            <div>
              <VasionListSelection
                :available-fields.sync="availableGroups"
                :selected-fields.sync="assignedGroups"
                :allow-field-ordering="false"
                :available-label-text="'All Groups'"
                :selected-label-text="'Permitted Groups'"
                :unique-id="'permissions-groups'"
                @update:availableFields="setEFormBuilderIsDirty"
                @update:selectedFields="setEFormBuilderIsDirty"
                @update:addAll="setEFormBuilderIsDirty"
                @update:removeAll="setEFormBuilderIsDirty"
              />
            </div>
          </div>
        </div>

        <div v-if="createTempDocsByFormID" class="temp-list-selection-styles">
          <VasionListSelection
            :available-fields.sync="availableSelections"
            :selected-fields.sync="selectedTemplates"
            :allow-field-ordering="false"
            :available-label-text="'Available Templates'"
            :selected-label-text="'Selected'"
            :unique-id="'templates-selection'"
            @update:availableFields="setEFormBuilderIsDirty"
            @update:selectedFields="setEFormBuilderIsDirty"
            @update:addAll="setEFormBuilderIsDirty"
            @update:removeAll="setEFormBuilderIsDirty"
          />
        </div>
      </div>
    </div>
    <div v-if="showMapTemplates && createTempDocsByFormID" class="map-forms">
      <h1>Map Templates</h1>
      <div class="vasion-settings-card">
        <div v-for="(template, index) in docTemplateMappingData" :key="index">
          <div class="header">
            <h2>{{ template.name }}</h2>
          </div>
          <div v-for="(field, index2) in template.templateFields" :key="index2" class="field-value">
            <span>{{ field.DocTemplateFieldName }}</span>
            <VasionDropList
              :key="&quot;newKey&quot;"
              v-slot="slotItem"
              v-model="field.EFormFieldID"
              :dataArray="eForm.FormFields.filter(f => f.FieldType !== 'SignatureButton')"
              :width="'310'"
              title="Fields"
              type="plain-list"
              displayName="LabelText"
              valueName="EFormFieldID"
            >
              {{ slotItem.item.LabelText }}
            </VasionDropList>
          </div>
        </div>
      </div>
    </div>
    <VasionGeneralModal
      :rejectButtonText="'CANCEL'"
      :confirmButtonText="'OK'"
      :confirmButtonDisabled="!folderSelected"
      :modalType="'slot'"
      :sync="showBrowseFoldersDialog"
      @confirmButtonClick="folderDialogOK"
      @noButtonClick="handleCancel"
    >
      <div class="folder-dialog-browse">
        <VasionFolders
          :disableRootSelect="true"
          @itemSelected="handleItemSelected"
        />
      </div>
    </VasionGeneralModal>
    <VasionSnackbar
      id="workflow-started-snack"
      :showRawHtml="showRawHtml"
      :showSnackbarBool.sync="showSnackbarBool"
      :snackbarImage="snackbarImage"
      :snackbarSubTitle="snackbarSubTitle"
      :snackbarTitle="snackbarTitle"
    />
    <NewFieldDialog
      ref="newFieldDialog"
      :builderMode="true"
      :config="newFieldConfig"
      :fieldTypeList="fieldTypeList"
      :fields.sync="fields"
      @saved="toggleComponentView(false)"
    />
    <md-dialog id="upload-new-file-dialog" class="dialog" :md-active.sync="showUploadDialog">
      <div class="upload-container">
        <h4>Recommended image size: 200w x 50h max size</h4>
        <div class="file-pond-block-wrapper">
          <file-pond ref="pond" :server="serverOptions" />
        </div>
        <VasionButton
          id="close-upload-dialog"
          :isFullWidth="false"
          :classProp="'secondary'"
          class="button-alignment"
          @vasionButtonClicked="showUploadDialog = !showUploadDialog"
        >
          CLOSE
        </VasionButton>
      </div>
    </md-dialog>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import { FormBuilder } from 'vue-formio';
import autoScroll from 'dom-autoscroller';
import { builderConfig } from '@/assets/js/formIOConfig'
import NewFieldDialog from '@/components/forms/NewFieldDialog.vue';
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'
import { addTotalLogicDropList, enrichTotalLogic } from '@/store/helperModules/forms.module'
import { toBase64 } from '@/store/helperModules/storage.module'

export default {
  name: 'FormioFormBuilder',
  components: {
    formbuilder: FormBuilder,
    Loading,
    NewFieldDialog,
  },
  props: {
    eForm: {
      type: Object,
      required: false,
      default: null
    },
  },
  data() {
    return {
      assignedGroups: [],
      assignedUsers: [],
      availableGroups: [],
      availableSelections: [],
      availableUsers: [],
      activelyFiltering: false,
      builderKey: 1,
      checkedDocumentsString: '1',
      chosenFolderID: '',
      config: addTotalLogicDropList(builderConfig, this.$store.state.forms.activeFormIoJSON),
      currentPage: 'form',
      databaseLookup: { name: '[None]', id: null },
      defaultTemplateMappingSave: {
        EFormID: this.eForm.EFormID,
        DocumentTemplateIDs: [],
      },
      docTemplateMappingData: [],
      eFormBuilderIsDirty: false,
      errorMessages: [],
      fields: [],
      fieldSearchInput: '',
      fieldTypeList: [
        { id: 1, value: 'Textbox' },
        { id: 2, value: 'Dropdown' },
        { id: 3, value: 'Date' },
        { id: 6, value: 'Number' },
        { id: 7, value: 'URL' },
        { id: 8, value: 'Email' },
        { id: 9, value: 'Checkbox' },
        { id: 10, value: 'Radio Button' },
        { id: 11, value: 'Currency' },
        { id: 12, value: 'Signature Field' },
        { id: 13, value: 'Text Area' },
      ],
      folderSelected: false,
      formDestinationFolder: '',
      formSelected: true,
      isDocumentLoaded: false,
      isLoading: false,
      loaderBackgroundColor: loaderBackgroundColor,
      loaderColor: loaderColor,
      localHeaderImage: '',
      newFieldConfig: {
        fieldUniqueName: { name: '<New Field>' },
        fieldLabel: '',
        fieldType: {},
        fieldLength: 100,
        fieldValues: '',
        fieldHidden: false,
        mandatory: false,
        readOnly: false,
        totalPageNo: false,
        systemDate: false,
      },
      saveEFormsPayload: this.eForm,
      selectedFolder: {},
      selectedTabName: 'build',
      selectedTemplates: [],
      selectedWorkflow: { name: '[None]', value: null },
      serverOptions: {
        process: this.processHandler,
      },
      showBrowseFoldersDialog: false,
      showFormioInputs: false,
      showFormLayoutAccess: false,
      showRawHtml: false,
      showSnackbarBool: false,
      showUploadDialog: false,
      snackbarImage: '',
      snackbarSubTitle: '',
      snackbarTitle: '',
      templateMappingSave: {
        EFormID: this.eForm.EFormID,
        FieldMappings: [],
      },
      uploadedFileDetail: '',
      uploadedfilePath: '',
      workflowList: [],
    }
  },
  computed: {
    activeFormIoJSON: {
      get: function () { return this.$store.state.forms.activeFormIoJSON },
      set: function () { },
    },
    availableTemplatesGetter() { return this.$store.getters['forms/availableTemplates'] },
    builderConfigFromFormID() { return this.$store.getters['forms/filterFormBuilderconfigByFormIDGetter'] },
    createTempDocsByFormID: {
      get: function () { return this.$store.state.forms.createTempDocsByFormID?.length ? this.$store.state.forms.createTempDocsByFormID : null },
      set: function () { },
      cache: false,
    },
    formHeaderImage() { return this.saveEFormsPayload.HeaderImageBase64 ? this.saveEFormsPayload.HeaderImageBase64 : '' },
    formName() { return this.saveEFormsPayload.Name ? this.saveEFormsPayload.Name : '' },
    groups() {
      return this.$store.state.common.groups
    },
    indexFieldsForForm() { return this.$store.state.common.indexFields },
    lookupList() {
      const filteredLookups = this.$store.state.fieldMapping.lookupList.filter(x => x.attributeFormID === this.$route.params.formID)
      filteredLookups.unshift({ name: '[None]', id: null })
      return filteredLookups
    },
    selectedTemplatesGetter() { return this.$store.getters['forms/selectedTemplates'] },
    showAdvanced() { return this.currentPage === 'advanced' },
    shouldBuilderLoad() {
      if (this.activeFormIoJSON.components.length || this.$route.params.formLayoutID === '0') {
        return true
      } else { return false }
    },
    showForm() { return this.currentPage === 'form' },
    showMapTemplates() { return this.currentPage === 'mapTemplates' },
    tempDocsForEform() { return this.$store.state.forms.tempDocsForEform },
    users() {
      return this.$store.state.common.users
    },
  },
  watch: {
    async activeFormIoJSON(newValue) {
      this.handleUpdatedActiveFormIoJSON(newValue)
    },
    availableTemplatesGetter() {
      this.availableSelections = this.availableTemplatesGetter
    },
    builderConfigFromFormID() {
      if (this.activelyFiltering) {
        this.updateBuilderConfig()
      }
    },
    selectedTemplates() {
      this.buildDoctemplateMappingData()
    },
    selectedTemplatesGetter() {
      this.selectedTemplates = this.selectedTemplatesGetter
    },
  },
  created: async function () {
    this.isLoading = true

    await Promise.all([
      this.$store.dispatch('common/getIndexFieldsForForm', this.$route.params.formID),
      this.$store.dispatch('forms/getFormBuilderConfigByFormID', this.$route.params.formID),
      this.$store.dispatch('common/getUsers'),
      this.$store.dispatch('common/getGroups'),
      this.$store.dispatch('forms/getCreateTempDocsByFormID', this.$route.params.formID),
    ])

    this.runComponentFilter('') // This is needed to reset the field filter
    this.config.noNewEdit = true
    const workflows = await this.$store.dispatch('workflow/getAllWorkflowsWithoutEForm')

    this.workflowList = workflows.map((workflow) => {
      return {
        name: workflow.sName,
        value: workflow.iID,
      }
    })

    if (this.eForm.WorkflowID !== null && this.eForm.WorkflowID > 0) {
      const tempWorkflow = this.workflowList.find(e => this.eForm.WorkflowID === e.value)

      if (tempWorkflow) {
        this.selectedWorkflow = tempWorkflow
      } else {
        // The selected workflow is not in the list, which is fine.  We just need to get the name
        const workflowSettingsData = await this.$store.dispatch('workflow/getWorkflowSettingsNoCommit', this.eForm.WorkflowID)
        if (workflowSettingsData && workflowSettingsData.wfID === this.eForm.WorkflowID) {
          this.selectedWorkflow = {
            name: workflowSettingsData.name,
            value: this.eForm.WorkflowID,
          }

          this.workflowList.unshift(this.selectedWorkflow)
        }
      }
    }

    this.workflowList.unshift({ name: '[None]', value: null })

    this.updateActiveJsonStore()
    this.updateBuilderConfig()
    if (this.$route.params.formLayoutID !== '0') {
      await this.$store.dispatch('forms/getCreateTempDocsByEFormID', this.eForm.EFormID)
    }
    if (this.formHeaderImage) {
      this.localHeaderImage = this.formHeaderImage
      this.isDocumentLoaded = true
    }
    await this.$store.dispatch('fieldMapping/getDatabaseLookups', true)
    this.isLoading = false
    if (this.showForm) {
      this.resetBuilderForm()
    }
    this.loadUsersAndGroups()

    this.saveEFormsPayload.PublicAccessible = this.eForm.PublicAccessible
    this.assignedUsers = this.saveEFormsPayload.AccessUserIDs.map(e => this.availableUsers.find(x => x.value === e))
    this.assignedGroups = this.saveEFormsPayload.AccessGroupIDs.map(e => this.availableGroups.find(x => x.value === e))
    this.availableUsers = this.availableUsers.filter(x => !this.assignedUsers.includes(x))
    this.availableGroups = this.availableGroups.filter(x => !this.assignedGroups.includes(x))

    if (this.eForm.DBLookupID !== null && this.eForm.DBLookupID > 0) {
      const tempLookup = this.lookupList.find(x => x.id === this.eForm.DBLookupID)
      if (tempLookup) {
        this.databaseLookup = tempLookup
      }
    }
  },
  methods: {
    async backToLayouts() {
      await this.$store.dispatch('forms/setDefaultFormTab', 'layouts')
      this.$router.push({ name: 'EditForm', params: { formID: this.$route.params.formID } })
    },
    async buildDoctemplateMappingData() {
      this.isLoading = true
      const returnArray = []
      if (!this.createTempDocsByFormID) {
        return false
      }
      await this.createTempDocsByFormID.map(async template => {
        const returnObject = {
          name: '',
          templateFields: [],
          EFormFieldID: '',
        }
        const templateFormFields = await this.$store.dispatch('forms/getEFormDocTemplateFields', { EFormID: this.eForm.EFormID, CreateTemplateDocID: template.id })
        returnObject.name = template.name
        returnObject.templateFields = [...templateFormFields.Collection.Values].map(i => { if (i.EFormFieldID === 0) { i.EFormFieldID = '' } return i })
        if (this.selectedTemplates.some(e => e.id === template.id)) {
          returnArray.push(returnObject)
        }
      })
      this.docTemplateMappingData = returnArray
      this.isLoading = false
    },
    calculatedClass(selectedTab) {
      return this.selectedTabName === selectedTab ? 'tab-selected' : 'tab'
    },
    clearAttributeFieldMappings() {
      this.saveEFormsPayload.FormFields.map(f => {
        f.MVMappedFieldName = '';
        return f
      })
    },
    clearTemplateSelectionOptions() {
      this.availableSelections = []
      this.selectedTemplates = []
    },
    componentAdded() {
      if (this.config.noNewEdit) {
        this.prepareEFormForSave()
      }
    },
    componentSaved() {
      this.prepareEFormForSave()
    },
    deleteHeaderImage() {
      this.localHeaderImage = ''
      this.isDocumentLoaded = false
    },
    editHeaderImage() {
      this.showUploadDialog = !this.showUploadDialog
    },
    folderDialogOK() {
      if (this.folderSelected) {
        this.showBrowseFoldersDialog = !this.showBrowseFoldersDialog
      }
    },
    handleCancel() {
      this.folderSelected = false
      this.showBrowseFoldersDialog = !this.showBrowseFoldersDialog
    },
    handleItemSelected(item) {
      this.folderSelected = true
      this.saveEFormsPayload.FolderID = item.value
      this.saveEFormsPayload.FolderName = item.name
    },
    async handleUpdatedActiveFormIoJSON(newValue) {
      await this.stringifyJsonReturn(newValue)
      await this.prepareEFormForSave()
      await this.updateActiveJsonStore()
    },
    loadUsersAndGroups() {
      this.availableUsers = this.users.map((user) => {
        return {
          name: user.name,
          value: user.value,
        }
      })
      this.availableGroups = this.groups.map((group) => {
        return {
          name: group.name,
          value: group.value,
        }
      })
    },
    mapIndexToEform() {
      this.saveEFormsPayload.FormFields = this.saveEFormsPayload.FormFields.map((f) => {
        f.MVMappedFieldName = f.MVMappedFieldName.value ? f.MVMappedFieldName.value : f.MVMappedFieldName
        return f
      })
    },
    onBuilderChange: function (schema) {
      // eslint-disable-next-line
      console.log("schema", schema);
    },
    onSubmitMethod: function (submission) {
      // eslint-disable-next-line
      console.log(submission);
    },
    async prepairDefaultTemplateMappingForSave() {
      this.selectedTemplates.map(t => {
        this.defaultTemplateMappingSave.DocumentTemplateIDs.push(t.id)
      })
    },
    async prepareEFormForSave() {
      this.isLoading = true
      const fields = await this.$store.dispatch('forms/getEFormFieldsFromFormIOJson', this.$route.params.formLayoutID)

      if (!fields) {
        // something bad happened server side - just back out for now
        this.isLoading = false
        return
      }

      if (this.eFormBuilderIsDirty) {
        this.saveEFormsPayload.FormFields = fields.FormFields
      }
      await this.$store.dispatch('forms/setActiveFormIoJSON', enrichTotalLogic(this.activeFormIoJSON))
      this.config = addTotalLogicDropList(this.config, this.$store.state.forms.activeFormIoJSON)
      this.updateBuilderConfig()
      this.isLoading = false
    },
    async prepairTemplateMappingForSave() {
      if (!this.docTemplateMappingData.length) { return false }
      this.docTemplateMappingData.forEach(template => {
        template.templateFields.map(f => {
          if (typeof f.EFormFieldID === 'object') {
            this.templateMappingSave.FieldMappings.push({ EFormFieldID: f.EFormFieldID.EFormFieldID, DocTemplateFieldID: f.DocTemplateFieldID })
          } else if ((typeof f.EFormFieldID === 'string' && f.EFormFieldID !== '') || typeof f.EFormFieldID === 'number') {
            this.templateMappingSave.FieldMappings.push({ EFormFieldID: f.EFormFieldID, DocTemplateFieldID: f.DocTemplateFieldID })
          }
        })
      })
    },
        // eslint-disable-next-line
    processHandler: async function (fieldName, file, metadata, load, error, progress, abort) {
      try {
          if (!file.name.match(/.(jpg|jpeg|png|bmp)$/i)) {
            this.snackbarTitle = 'Upload error '
            this.snackbarSubTitle = 'Only .png, .jpg, .jpeg, or .bmp file types supported'
            this.snackbarImage = false
            this.showSnackbarBool = true;
          } else {
            this.localHeaderImage = await toBase64(file)
            this.isDocumentLoaded = true
          }

          progress(true, 0, 1024);
          load(file.name)
          this.showUploadDialog = false
        return {
          abort: () => {
            if (abort !== undefined) {
              abort();
            }
          },
        };
      } catch (err) {
        console.warn(err)
      }
    },
    async rebuild() {
      this.builderKey += 1
    },
    async removeHandler() {
      await this.updateActiveJsonStore()
      this.updateBuilderConfig()
    },
    resetBuilderForm() {
      // eslint-disable-next-line
      let scroll = autoScroll([
              window,
              document.querySelector('.scroll-me'),
          ], {
          margin: 20,
          maxSpeed: 15,
          scrollWhenOutside: true,
          autoScroll: function () {
              return this.down;
          },
      });
      this.$nextTick(() => {
        if (this.$refs.formBuilder) {
          this.$refs.formBuilder.builder.setForm(this.$store.state.forms.activeFormIoJSON)
        }
      })
    },
    async runComponentFilter(value) {
      this.activelyFiltering = true
      await this.$store.dispatch('forms/filterFormBuilderconfigByFormID', value)
      this.activelyFiltering = false
    },
    async save() {
      if (!this.validate()) {
        return false
      }
      await this.saveEform()
      this.saveTemplateMapping()
    },
    async saveEform() {
      this.isLoading = true
      await this.handleUpdatedActiveFormIoJSON(this.activeFormIoJSON)

      this.saveEFormsPayload.WorkflowID = this.selectedWorkflow?.value
      this.saveEFormsPayload.AccessUserIDs = this.saveEFormsPayload.PublicAccessible ? [] : this.assignedUsers.map(e => e.value)
      this.saveEFormsPayload.AccessGroupIDs = this.saveEFormsPayload.PublicAccessible ? [] : this.assignedGroups.map(e => e.value)

      await this.mapIndexToEform()
      this.saveEFormsPayload.HeaderImageBase64 = this.localHeaderImage
      if (this.eFormBuilderIsDirty) {
        this.saveEFormsPayload.FormIOJSON = JSON.stringify(this.activeFormIoJSON)
      }
      this.saveEFormsPayload.DBLookupID = this.databaseLookup.id
      try {
        const res = await this.$store.dispatch('forms/saveEForm', this.saveEFormsPayload)
        if (res?.EFormID && res.EFormID !== 0) {
          this.templateMappingSave.EFormID = res.EFormID
          this.defaultTemplateMappingSave.EFormID = res.EFormID
          this.$store.dispatch('forms/getEForm', res.EFormID)
          this.saveEFormsPayload.EFormID = res.EFormID
          this.$store.dispatch('forms/updateAreFormsDirty', false)
          this.isLoading = false
          this.showSnackbar(true)
            if (!this.createTempDocsByFormID) {
              setTimeout(() => {
                this.backToLayouts()
              }, 1500)
            }
        } else {
          this.isLoading = false
          this.showSnackbar(false)
        }
      } catch {
          this.isLoading = false
          this.showSnackbar(false)
      }
    },
    async saveTemplateMapping() {
      this.isLoading = true
      await this.prepairDefaultTemplateMappingForSave()
      const res = await this.$store.dispatch('forms/saveEFormDocTemplateDefaultMapping', this.defaultTemplateMappingSave)
      if (res) {
        this.$store.dispatch('forms/updateAreFormsDirty', false)
        this.showSnackbar(true)
        this.isLoading = false
        setTimeout(() => {
          this.backToLayouts()
        }, 1500)
      } else {
        this.isLoading = false
        this.showSnackbar(false)
      }
    },
    setCurrentPage(newVal) {
      this.currentPage = newVal
    },
    setEFormBuilderIsDirty() {
      this.eFormBuilderIsDirty = true
    },
    setEformFromProps() {
      if (this.eForm.Name) { this.saveEFormsPayload = this.eForm }
    },
    showSnackbar(condition) {
        this.showRawHtml = false
        this.snackbarTitle = condition ? `${$layoutsLabel} successfully saved!` : `Unable to save ${$layoutsLabel}.`
        this.snackbarSubTitle = condition ? `You can view your new ${$layoutsLabel} in "All ${$formsLabelPlural}"` : 'Please try again or contact your administrator'
        // eslint-disable-next-line
        this.snackbarImage = condition ? true : false
        this.showSnackbarBool = true;
    },
    stringifyJsonReturn(newVal) {
      if (this.eFormBuilderIsDirty) {
        this.saveEFormsPayload.FormIOJSON = JSON.stringify(newVal)
      }
    },
    async toggleComponentView(showDefaults) {
      this.showFormioInputs = showDefaults
      this.config.builder.custom.ignore = showDefaults
      this.config.builder.customBasic.ignore = !showDefaults
      this.config.builder.customAdvanced.ignore = !showDefaults
      this.config.noNewEdit = !showDefaults
      this.updateBuilderConfig()
    },
    updateActiveJsonStore() {
      this.$store.dispatch('forms/setActiveFormIoJSON', this.activeFormIoJSON)
    },
    async updateBuilderConfig(newConfig) {
      if (newConfig) {
        this.config.builder.custom.components = newConfig
      } else {
        await this.$store.dispatch('forms/getFormBuilderConfigByFormID', this.$route.params.formID)
        this.config.builder.custom.components = this.builderConfigFromFormID
      }

      await this.rebuild()
      if (this.$refs.formBuilder) {
        this.$refs.formBuilder.builder.build(this.$store.state.forms.activeFormIoJSON)
      }
    },
    updateFormForSave: async function (e) {
      // eslint-disable-next-line
      const obj = {
        label: e.label,
        type: e.type,
        input: e.input,
        key: e.key,
        tableView: e.tableView,
        linkedField: '',
      }
      await this.$store.dispatch('forms/updateActiveForm', obj)
    },
    updateSelectedTab(newSelectedTab) {
      this.selectedTabName = newSelectedTab
    },
    validate() {
      let valid = false
      valid = this.validateFormPage()

      if (!valid) {
        this.snackbarTitle = 'Unable to validate page'
        this.showRawHtml = true
        this.snackbarSubTitle = this.errorMessages.join('<br />')
        this.snackbarImage = false
        this.showSnackbarBool = true;
        this.errorMessages = []
      }

      return valid
    },
    validateFormPage() {
      let valid = true

      if (!this.saveEFormsPayload || !this.saveEFormsPayload.Name || this.saveEFormsPayload.Name === '') {
        valid = false
        this.errorMessages.push(`- Please provide a name for the ${$layoutsLabel.toLowerCase()}.`)
      }

      if (this.saveEFormsPayload.Name.trim() === '') {
        valid = false
        this.errorMessages.push('- The Form Name cannot only include spaces.')
      }

      if (!this.saveEFormsPayload || !this.saveEFormsPayload.Description || this.saveEFormsPayload.Description === '') {
        valid = false
        this.errorMessages.push(`- Please provide a description for the ${$layoutsLabel.toLowerCase()}.`)
      }

      if (this.saveEFormsPayload.Description.trim() === '') {
        valid = false
        this.errorMessages.push('- The Form Description cannot only include spaces.')
      }

      const filteredComponents = this.activeFormIoJSON?.components.filter(c => c.key !== 'submit')
      if (!filteredComponents || !filteredComponents.length > 0) {
        valid = false
        this.errorMessages.push(`- Please provide at least one field on the ${$layoutsLabel.toLowerCase()}.`)
      }

       if (!this.saveEFormsPayload || !this.saveEFormsPayload.FolderName || this.saveEFormsPayload.FolderName === '') {
        valid = false
        this.errorMessages.push('- Please provide a destination folder.')
      }

      return valid
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/css/variables.scss';

#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
}
#browse-folders-for-eform {
  position: relative;
  top: 16px;
  margin-right: 40px;
}

#new-formio-title {
  width: 500px;
  position: absolute;
  margin-top: -35px;
  margin-left: -15px;
}

.droplist{
  margin-bottom: 24px;
}

.description-text{
  font-size: 14px;
  color: #838587;
  margin-bottom: 24px;
}

.builder-components {
  border-radius: 0px 0px 8px 8px;
  width: 1004px;
  overflow-y: inherit;
  margin: 0 auto;
  margin-top: 30px;
}

.collapse-button{
  margin-right: 16px;
  align-items: center;
  background-color: #f1f2f3;
  border-radius: 8px;
}

.collapsible-section{
  display: flex;
  align-items: center;
  margin-top: 36px;
}

.subheader{
  font-size: 20px;
}

.custom-logo-extension {
  width: 1004px;
  height: 172px;
  background-color: $white;
  position: relative;
  top: 120px;
  right: 9px;
  margin: 0 auto;
  // display: flex;
  // justify-content: center;
  // align-items: center;
}
.upload-container {
  border-radius: 1px solid red;
  overflow: auto;
}

.eform-buttons {
  position: relative;
  bottom:147px;
  left: 0px;
}
.eformButtonsNoAddition {
  position: relative;
  bottom: 1px;
  left: 6px;
}
.formio-builder-form {
  padding-top: 1px !important;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.folderText {
  text-align: left;
}
.folder-dialog-browse {
  width: 400px;
  height: 300px;
  overflow: auto;
  z-index: 30;
}
.formio-extension {
  width: 1004px;
  height: 122px;
  background-color: $white;
  position: relative;
  top: 416px;
  right: 9px;
  z-index: 1;
  margin: 0 auto;
}
.formio-input-extension {
  padding-top: 10px;
  background-color: white;
  z-index: 200;
  display: flex;
  flex-direction: column;
}
.formio-header {
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  position: relative;
  box-shadow: 0 1px 0 0 #e4e5e6;
  padding-bottom: 15px;
  z-index: 2;
}
.form-name {
  border: none;
  font-size: 24px;
  font-weight: bold;
  width: 100%;
  padding: 30px 24px 8px 24px;
  outline: none;
  border-radius: 8px 8px 0 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.form-name:focus,
.form-description:focus {
  background-color: $grey-75;
}
.form-description {
  border: none;
  width: 100%;
  font-size: 18px;
  padding: 0px 0px 30px 24px;
  outline: none;
  border-bottom: 1px solid #d6d8da;
}
.header {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.header-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header-image {
  height: 100%;
  box-sizing: border-box;
  max-width: 100%;
  max-height: 100%;
  width: auto;
}
.header-icon-buttons {
  position: absolute;
  top: 15px;
  right: 5px;
  z-index: 100;
}
.highlightBackground {
  min-width: 34px;
  min-height: 34px;
  background-color: $grey-75;
  border-radius: 8px;
}
.highlightBackground:after {
  background-color: $grey-100;
}
.field-value {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.left-padding {
  padding-left: 15px;
}
.left-pannel {
  height: 0;
  width: 84.1%;
}
.form-builder-line-item {
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $grey-75;
  padding: 10px 0px;
}
.form-builder-line-item-name {
  position: relative;
  top: 8px;
}
.form-builder-panel {
  position: relative;
  top: 170px;
}
.margin-bottom {
  margin-bottom: 22px;
  color: $grey-400;
}
.form-builder-margin-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}
.fixedWidth {
  width: 250px;
}
.md-overlay {
  z-index: 20;
}
.md-dialog {
  z-index: 50;
}
.form-builder-search-group {
  display: flex;
  justify-content: space-between;
  padding-bottom: 38px;
}
.horizontal-line {
  border-bottom: 1px solid $grey-300;
  margin: 30px 0px;
}
.quick-find-input-extension {
  margin: 7px;
}
.scroll-me {
  height: calc(100vh - 56px);
  overflow-y: auto;
}
.temp-list-selection-styles {
  min-height: 300px;
  margin-top: 20px;
}
.tabs {
  position: absolute;
  left: -5px;
  top: 30px;
}
.vasion-settings-card {
  width: 100%;
  min-height: calc(100vh - 130px);
  z-index: 3000;
  background-color: $white;
  padding: 40px;
}
.vasion-input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
#vasion-form-builder .formio-form {
  position: relative;
  min-height: 80px;
  top: 295px;
  z-index: 5;
}
.with-fix {
  padding: 0px 39px 0px 18px;
  position: absolute;
  left: 1px;
  top: 73px;
}
.row-checkbox {
  margin-top: 30px;
  margin-left: 16px;
}
@media screen and (max-width: 1535px) {
  .builder-components {
  width: 890px;
  }
  .formio-extension {
    width: 890px;
  }
  .custom-logo-extension {
    width: 890px;
  }
}
@media screen and (max-width: 1400px) {
  .builder-components {
  width: 700px;
  }
  .formio-extension {
    width: 700px;
  }
  .custom-logo-extension {
    width: 700px;
  }
}
</style>
