const defaultIgnoreItems = [
  {
    key: 'api',
    ignore: false,
    components: [
      { key: 'key', ignore: false },
      { key: 'tags', ignore: true },
      { key: 'properties', ignore: true },
    ],
  },
  { key: 'conditional', ignore: false },
  { key: 'logic', ignore: true },
  { key: 'layout', ignore: true },
  { key: 'hidden', ignore: true },
  {
    key: 'display',
    ignore: false,
    components: [
      { key: 'prefix', ignore: true },
      { key: 'suffix', ignore: true },
      { key: 'widget.type', ignore: true },
      { key: 'widget', ignore: true },
      { key: 'inputMask', ignore: true },
      { key: 'allowMultipleMasks', ignore: true },
      { key: 'inputMasks', ignore: true },
      { key: 'customClass', ignore: true },
      { key: 'tabindex', ignore: true },
      { key: 'hidden', ignore: true },
      { key: 'hideLabel', ignore: true },
      { key: 'showWordCount', ignore: true },
      { key: 'showCharCount', ignore: true },
      { key: 'mask', ignore: true },
      { key: 'autofocus', ignore: true },
      { key: 'spellcheck', ignore: true },
      { key: 'dataGridLabel', ignore: true },
      { key: 'disabled', ignore: true },
      { key: 'tableView', ignore: true },
      { key: 'modalEdit', ignore: true },
    ],
  },
  {
    key: 'data',
    ignore: false,
    components: [
      { key: 'persistent', ignore: true },
      { key: 'protected', ignore: true },
      { key: 'dbIndex', ignore: true },
      { key: 'encrypted', ignore: true },
      { key: 'redrawOn', ignore: true },
      { key: 'clearOnHide', ignore: true },
      { key: 'customDefaultValuePanel', ignore: true },
      { key: 'calculateValuePanel', ignore: true },
      { key: 'allowCalculateOverride', ignore: true },
    ],
  },
]

const builderConfig = {
  noNewEdit: '',
  builder:
  {
  basic: false,
  advanced: false,
  premium: false,
  data: false,
  customBasic: {
      title: 'Basic Components',
      default: true,
      weight: 0,
      ignore: true, // You cannot just delete this section or there will be a parsing error below because the components will not exist to set our field type edits to. So just set the ignore flag to true.
      components: {
          textfield: true,
          textarea: true,
          number: true,
          checkbox: true,
          selectboxes: true,
          radio: true,
          select: true,
      },
  },
  customAdvanced: {
      title: 'Advanced',
      default: true,
      weight: 0,
      ignore: true, // You cannot just delete this section or there will be a parsing error below because the components will not exist to set our field type edits to. So just set the ignore flag to true.
      components: {
          email: true,
          url: true,
          datetime: true,
          day: true,
          time: true,
          currency: true,
          file: true,
          datagrid: true,
          columns: true,
          content: true,
      },
  },
  custom: {
    title: 'Fields',
    weight: 10,
    default: true,
    components: {},
  },
  layout: false,
  },
  editForm: {
    textfield: [...defaultIgnoreItems],
    textarea: [...defaultIgnoreItems],
    number: [...defaultIgnoreItems],
    checkbox: [...defaultIgnoreItems],
    selectboxes: [...defaultIgnoreItems],
    select: [...defaultIgnoreItems],
    radio: [...defaultIgnoreItems],
    email: [...defaultIgnoreItems],
    url: [...defaultIgnoreItems],
    datetime: [...defaultIgnoreItems],
    day: [...defaultIgnoreItems],
    time: [...defaultIgnoreItems],
    currency: [...defaultIgnoreItems],
    signature: [...defaultIgnoreItems],
  },
}

export {
  builderConfig,
  defaultIgnoreItems,
}
