import { defaultIgnoreItems } from '@/assets/js/formIOConfig'

// eslint-disable-next-line consistent-return
const addTotalLogicDropList = (response, form) => {
  const numericalColumList = []
  const dataGridComponents = form.components.filter(i => {
    return i.type === 'datagrid'
  })

  if (dataGridComponents.length >= 1) {
    // eslint-disable-next-line array-callback-return
    dataGridComponents.map(item => {
      // eslint-disable-next-line array-callback-return
      item.components.map(column => {
        if (column.type === 'number' || column.type === 'currency') {
          numericalColumList.push({
            label: `[${item.label}] - ${column.label}`,
            value: `grid:${item.key}value:${column.key}`,
          })
        }
      })
    })
  } else {
    return response
  }
  const newNumberIgnoreItems = [
    ...defaultIgnoreItems.slice(0, 6),
    {
      key: 'data',
      ignore: false,
      components: [
        { key: 'multiple', ignore: true },
        { key: 'persistent', ignore: true },
        { key: 'protected', ignore: true },
        { key: 'dbIndex', ignore: true },
        { key: 'encrypted', ignore: true },
        { key: 'redrawOn', ignore: true },
        { key: 'clearOnHide', ignore: true },
        { key: 'customDefaultValuePanel', ignore: true },
        { key: 'calculateValuePanel', ignore: false },
        { key: 'allowCalculateOverride', ignore: false },
        {
          label: 'Total Column',
          data: {
              values: numericalColumList,
          },
          key: 'selectTotal',
          type: 'select',
          input: true,
        },
      ],
    },
  ]
  response.editForm.number = [...newNumberIgnoreItems]
  response.editForm.currency = [...newNumberIgnoreItems]
  const newResponse = {
    noNewEdit: '',
    builder: response.builder,
    editForm: response.editForm,
  }
  return newResponse
}

const enrichTotalLogic = (form) => {
  // eslint-disable-next-line array-callback-return
  form.components.map(c => {
    if (c.selectTotal && !c.calculateValue) {
      const rawString = c.selectTotal
      const grid = rawString.substring(
        rawString.indexOf(':') + 1,
        rawString.indexOf('value:'),
      )
      const filterValue = rawString.substring(rawString.lastIndexOf(':') + 1)
      // eslint-disable-next-line quotes
      c.calculateValue = `var GRID_FIELD = '${grid}';\r\nvar dataGrid = data[GRID_FIELD] || [];\r\nvar FIELD = '${filterValue}';\r\nvar sum = 0;\r\nfor (var i = 0; i <= dataGrid.length; i++)\r\n{\r\nif (!!dataGrid[i] && dataGrid[i].hasOwnProperty(FIELD))\r\n{\r\nsum = sum + Number(dataGrid[i][FIELD]);\r\n}\r\n}\r\nvalue = sum;`
      c.disabled = true
      c.redrawOn = 'dataGrid'
    }
  })
  return form
}

export {
  addTotalLogicDropList,
  enrichTotalLogic,
}
