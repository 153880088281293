<template>
  <div class="main-div vasion-calc-height">
    <div class="inner-div">
      <div v-if="shouldEformBuilderLoad" class="form-div">
        <FormioFormBuilder
          v-if="eForm"
          :key="'new'"
          ref="FormBuilder"
          :eForm="eForm"
        />
      </div>
    </div>
  </div>
</template>

<script>
import FormioFormBuilder from '@/components/forms/FormioFormBuilder.vue';

export default {
  name: 'TheNewFormView',
  components: {
    FormioFormBuilder,
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch('forms/clearEform')
    if (this.shouldEformBuilderLoad) {
      this.$refs.FormBuilder.clearTemplateSelectionOptions()
    }
    next()
  },
  props: {
    templateSave: {
      type: Boolean,
      required: false,
    },
  },
  data: function() {
    return {
      eForm: null,
      formsURL: null
    }
  },
  computed: {
    activeFormIoJSON: {
      get: function () { return this.$store.state.forms.activeFormIoJSON },
      set: function () { },
    },
    shouldEformBuilderLoad() {
      if (this.activeFormIoJSON?.components?.length || this.$route.params.formLayoutID === '0') {
        return true
      } else { return false }
    },
  },
  created: async function () {
    await Promise.all([
      this.$store.dispatch('forms/getEForm', this.$route.params.formLayoutID),
      this.$store.dispatch('common/getIndexFieldsForForm', this.$route.params.formID)
    ])

    this.eForm = this.$store.state.forms.eForm
    this.eForm.IndexFormID = this.$route.params.formID

    this.formsURL = this.$store.state.common.formsURL
  },
  methods: {
  },
}
</script>

<style lang="scss" scoped>
  .button-div {
    margin: auto;
    margin-top: 40px;
    width: 288px;
  }

  .inner-div {
    width: 100%;
    height: 72px;
  }

  .instructional-message-div {
    margin-top: 24px;
  }

  .main-div {
    text-align: center;
    width: 100%;
    background-color: #f1f2f3;
  }
  .i-frame {
    width:2400px;
    height:1000px;
    border:none; margin:-20px;
  }
</style>
